import React from "react";
import { StyledMobileTabs } from "../styles";
import skills_2 from "../imgs/skills_2.svg";
import user from "../imgs/user.svg";
import experience_2 from "../imgs/experience_2.svg";
import projects_2 from "../imgs/projects_2.svg";
import graduation_logo from "../imgs/graduation_logo.svg";
import contact_icon_2 from "../imgs/contact_icon_2.svg";
import resume_icon from "../imgs/resume_icon.svg";
import home from "../imgs/home.svg";
import "./Tabs.css";
import { NavLink } from "react-router-dom";

const MobileTabs = props => {
  const { toggleTabsDrawer, setToggleTabsDrawer } = props;
  const handleMobileTabs = e => {
    e.preventDefault();
    setToggleTabsDrawer(!toggleTabsDrawer);
  };
  return (
    <StyledMobileTabs id="tabs">
      <button onClick={handleMobileTabs}>
        <NavLink className="home" to="/">
          <img src={home} alt="user icon" />
          Home
        </NavLink>
      </button>
      <button onClick={handleMobileTabs}>
        <NavLink className="visible" to="/about">
          <img src={user} alt="user icon" />
          About
        </NavLink>
      </button>
      <button onClick={handleMobileTabs}>
        <NavLink className="exp" to="/experience">
          {" "}
          <img src={experience_2} alt="experience icon" /> Experience
        </NavLink>
      </button>
      <button onClick={handleMobileTabs}>
        <NavLink className="pro" to="projects">
          {" "}
          <img src={projects_2} alt="projects icon" /> Projects
        </NavLink>
      </button>
      <button onClick={handleMobileTabs}>
        <NavLink className="ski" to="skills">
          {" "}
          <img src={skills_2} alt="skills icon" /> Skills
        </NavLink>
      </button>
      <button onClick={handleMobileTabs}>
        <NavLink className="graduation" to="education">
          {" "}
          <img src={graduation_logo} alt="education icon" /> Education
        </NavLink>
      </button>
      <a
        className="Resume"
        href="https://drive.google.com/file/d/14hLmtusyxbNt0OeaUowTNGYrvY0XxsDX/view?usp=sharing"
      >
        {" "}
        <img src={resume_icon} alt="resume icon" /> Resume{" "}
      </a>

      <button onClick={handleMobileTabs}>
        <NavLink to="contact">
          {" "}
          <img src={contact_icon_2} alt="contact icon" />
          Contact
        </NavLink>
      </button>
    </StyledMobileTabs>
  );
};

export default MobileTabs;
