import React from "react";
import { StyledSkills, StyledSkillsCard } from "../styles";
import {
  jsSkills,
  htmlSkills,
  backendSkills,
  cssSkills,
  otherSkills
} from "./skillsData";

const Skills = () => {
  return (
    <StyledSkills>
      <h1>Skills</h1>
      <StyledSkillsCard>
        <div>
          <h2>HTML</h2>
          <div>
            <img src={htmlSkills.htmlLogo} alt="skills icon" />
            <img src={htmlSkills.jsxLogo} alt="skills icon" />
          </div>
        </div>
        <div>
          <h2>CSS</h2>
          <div>
            <img src={cssSkills.css3} alt="skills icon" />
            <img src={cssSkills.lessLogo} alt="skills icon" />
            <img src={cssSkills.styledComponents} alt="skills icon" />
          </div>
        </div>
        <div>
          <h2>JavaScript</h2>
          <div>
            <img src={jsSkills.jsLogo} alt="skills icon" />
            <img src={jsSkills.reactLogo} alt="skills icon" />
          </div>
        </div>
        <div>
          <h2>BackEnd</h2>
          <div>
            <img src={backendSkills.nodeLogo} alt="skills icon" />
            <img src={backendSkills.postgressLogo} alt="skills icon" />
            <img src={backendSkills.expressLogo} alt="skills icon" />
            <img src={backendSkills.sqliteLogo} alt="skills icon" />
            <img src={backendSkills.knexLogo} alt="skills icon" />
            <img src={backendSkills.restLogo} alt="skills icon" />

          </div>
        </div>
        <div>
          <h2>Others</h2>
          <div>
            <img src={otherSkills.githubLogo} alt="skills icon" />
            <img src={otherSkills.herokuLogo} alt="skills icon" />
            <img src={otherSkills.netlifyLogo} alt="skills icon" />
            <img src={otherSkills.ccnaLogo} alt="skills icon" />

          </div>
        </div>
    </StyledSkillsCard>
    </StyledSkills>
  );
};

export default Skills;
