import React from "react";
import {StyledAbout} from "../styles";



const About = () => {

    return (<StyledAbout>
        <h1>About</h1>
        <h5>
        “You cannot discover new oceans unless you have <br/> the courage to lose sight of the shore.”
        <br /> – André Gide
      </h5>
      <p> Since beginning my journey as a telecom engineer 7 years ago,
         I've been part of<br/> teams  and projects that span the aviation,
          financial  services and telecoms industries. <br/> <br/>
          As a web developer, I am  heavily invested in taking part in projects that matter.<br/> I enjoy challenges and problem  solving.
          I'm quietly confident, naturally curious, <br/> and perpetually working on improving my  programming skills. </p>
        <h3>Current Focus: <span>JavaScript//React.js//Node.js </span> </h3>
    </StyledAbout>)
}

export default About;