import React from "react";
import { StyledProjects, StyledProCard } from "../styles";
import { projectsData } from "./projectsData";
import github_logo_3 from "../imgs/github_logo_3.jpg";
import external from "../imgs/external.svg";

const Projects = () => {
  return (
    <StyledProjects>
      <h1>Projects</h1>
      <div>
        {projectsData.map((project, index) => {
          return (
            <StyledProCard key={index}>
              <img src={project.img} alt="restaurant" />
              <div>
                <h5>{project.title}</h5>
                <p>Technology stack : {project.tech_stack}</p>
                <div>
                  <a href={project.github}>
                    <img src={github_logo_3} alt="github icon" />
                  </a>{" "}
                  <br />
                  <a href={project.website}>
                    <img src={external} alt="external link icon" />
                  </a>
                </div>
              </div>
            </StyledProCard>
          );
        })}
      </div>
    </StyledProjects>
  );
};

export default Projects;
