import React, { useState } from "react";
import Tabs from "./components/Tabs";
import Home from "./components/Home";
import { StyledApp,StyledMobileApp } from "./styles";
import { Route } from "react-router-dom";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Education from "./components/Education";
import Contact from "./components/Contact";
import MobileTabs from "./components/MobileTabs";
import BackDrop from "./components/BackDrop";
import TabView from "./components/TabView";

function App() {
  const [toggleTabsDrawer, setToggleTabsDrawer] = useState(false);
  if (window.screen.width <= "1024") {
    return (
      <StyledMobileApp>
        <Route
          path="/"
          render={props => (
            <TabView
              {...props}
              setToggleTabsDrawer={setToggleTabsDrawer}
              toggleTabsDrawer={toggleTabsDrawer}
            />
          )}
        />
        <Route
          exact
          path="/"
          render={props => (
            <Home
              {...props}
            />
          )}
        />
        <Route exact path="/about" render={props => <About {...props} />} />
        <Route
          exact
          path="/experience"
          render={props => <Experience {...props} />}
        />
        <Route
          exact
          path="/projects"
          render={props => <Projects {...props} />}
        />
        <Route exact path="/skills" render={props => <Skills {...props} />} />
        <Route
          exact
          path="/education"
          render={props => <Education {...props} />}
        />
        <Route exact path="/contact" render={props => <Contact {...props} />} />
        {toggleTabsDrawer ? (
          <MobileTabs
            setToggleTabsDrawer={setToggleTabsDrawer}
            toggleTabsDrawer={toggleTabsDrawer}
          />
        ) : (
          <BackDrop />
        )}
      </StyledMobileApp>
    );
  }
  return (
    <StyledApp>
      <Route path="/" render={props => <Tabs {...props} />} />
      <Route
        exact
        path="/"
        render={props => (
          <Home
            {...props}
            setToggleTabsDrawer={setToggleTabsDrawer}
            toggleTabsDrawer={toggleTabsDrawer}
          />
        )}
      />
      <Route exact path="/about" render={props => <About {...props} />} />
      <Route
        exact
        path="/experience"
        render={props => <Experience {...props} />}
      />
      <Route exact path="/projects" render={props => <Projects {...props} />} />
      <Route exact path="/skills" render={props => <Skills {...props} />} />
      <Route
        exact
        path="/education"
        render={props => <Education {...props} />}
      />
      <Route exact path="/contact" render={props => <Contact {...props} />} />
    </StyledApp>
  );
}

export default App;
