import html5_logo_2 from "../imgs/html5_logo_2.png";
import jsx_logo from "../imgs/jsx_logo.jpg";
import less_logo from "../imgs/less_logo.png";
import css3_logo from "../imgs/css3_logo.svg";
import styled_components_logo from "../imgs/styled_components_logo.png";
import nodejs_logo from "../imgs/nodejs_logo.png";
import react_logo from "../imgs/react_logo.png";
import js_logo from "../imgs/js_logo.jpg";
import knex_logo from "../imgs/knex_logo.png";
import postgresql_logo from "../imgs/postgresql_logo.png";
import rest_api_logo from "../imgs/rest_api_logo.png";
import express_logo from "../imgs/express_logo.png";
import sqlite_logo from "../imgs/sqlite_logo.png";
import github_logo_4 from "../imgs/github_logo_4.jpg";
import netlify_logo from "../imgs/netlify_logo.png";
import heroku_logo from "../imgs/heroku_logo.png";
import ccna_logo from "../imgs/ccna_logo.jpg";

export const htmlSkills = {
    htmlLogo: html5_logo_2,
    jsxLogo: jsx_logo
}

export const cssSkills = {
    lessLogo:less_logo,
    styledComponents:styled_components_logo,
    css3:css3_logo

}

export const jsSkills = {
    jsLogo:js_logo,
    reactLogo: react_logo
} 

export const backendSkills  = {
    nodeLogo: nodejs_logo,
    postgressLogo:postgresql_logo,
    expressLogo: express_logo,
    sqliteLogo:sqlite_logo,
    knexLogo:knex_logo,
    restLogo:rest_api_logo
}

export const otherSkills = {
    githubLogo:github_logo_4,
    herokuLogo:heroku_logo,
    netlifyLogo:netlify_logo,
    ccnaLogo:ccna_logo

}