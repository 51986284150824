import React from "react";
import { StyledContactUs } from "../styles";
import linkedIn_logo from "../imgs/linkedIn_logo.png";
import twitter_logo from "../imgs/twitter_logo.jpg";
import email_icon from "../imgs/email_icon.svg";
import github_logo_6 from "../imgs/github_logo_6.png";

const Contact = () => {
  return (
    <StyledContactUs>
      <h1>Contact</h1>
      <div>
        <h2>Reach out to me on social media</h2>
        <div>
          <a href="https://www.linkedin.com/in/awuorotienom/">
            <img src={linkedIn_logo} alt="Linkedin icon" />
          </a>
          <a href="https://twitter.com/gentlestrength_">
            <img src={twitter_logo} alt="twitter icon" />
          </a>
          <a href="mailto:otieno.awuor.m@gmail.com">
            <img src={email_icon} alt="email icon" />
          </a>
          <a href="https://github.com/awuorm">
            <img src={github_logo_6} alt="github icon" />
          </a>
        </div>
      </div>
    </StyledContactUs>
  );
};

export default Contact;
