import React from "react";
import { StyledTab } from "../styles";
import skills_2 from "../imgs/skills_2.svg";
import user from "../imgs/user.svg";
import experience_2 from "../imgs/experience_2.svg";
import projects_2 from "../imgs/projects_2.svg";
import graduation_logo from "../imgs/graduation_logo.svg";
import contact_icon_2 from "../imgs/contact_icon_2.svg";
import resume_icon from "../imgs/resume_icon.svg";
import "./Tabs.css";
import { NavLink } from "react-router-dom";

const Tabs = () => {
  return (
    <StyledTab id="tabs">
      <div>
        <h4>
          Mildred <br /> Awuor
        </h4>
        <p>Fullstack Web Developer</p>
      </div>
      <NavLink className="visible" to="/about">
        <img src={user} alt="user icon" />
        About
      </NavLink>

      <NavLink className="exp" to="/experience">
        {" "}
        <img src={experience_2} alt="experience icon" /> Experience
      </NavLink>

      <NavLink className="pro" to="projects">
        {" "}
        <img src={projects_2} alt="projects icon" /> Projects
      </NavLink>

      <NavLink className="ski" to="skills">
        {" "}
        <img src={skills_2} alt="skills icon" /> Skills
      </NavLink>
      <NavLink className="graduation" to="education">
        {" "}
        <img src={graduation_logo} alt="education icon" /> Education
      </NavLink>
      <a
        className="Resume"
        href="https://drive.google.com/file/d/14hLmtusyxbNt0OeaUowTNGYrvY0XxsDX/view?usp=sharing"
      >
        {" "}
        <img src={resume_icon} alt="resume icon" /> Resume{" "}
      </a>
      <NavLink to="contact"> <img src={contact_icon_2} alt="contact icon" />Contact</NavLink>
    </StyledTab>
  );
};

export default Tabs;
