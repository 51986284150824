import React from "react";
import { StyledEducation } from "../styles";
import lambda_logo_2 from "../imgs/lambda_logo_2.png";
import moi_logo from "../imgs/moi_logo.png";

const Education = () => {
  return (
    <StyledEducation>
      <h1>Education</h1>
      <div>
        <div>
          <div>
            <img src={lambda_logo_2} alt="edu logo" />
            <h2>Lambda School</h2>
          </div>
          <h2>Software Engineering,Fullstack Web Development.</h2>
          <h2>Location: San Francisco - California, USA.</h2>
        </div>
        <div>
          <div>
            <img src={moi_logo} alt="edu logo" />
            <h2>Moi University</h2>
          </div>
          <h2>B.Eng Electrical and Telecommunications Engineering.</h2>
          <h2>Location: Eldoret,Kenya.</h2>
        </div>
      </div>
    </StyledEducation>
  );
};

export default Education;
