import React from "react";
import { StyledTabView } from "../styles";
import menu_icon from "../imgs/menu_icon.png";

const TabView = (props) => {
    const { toggleTabsDrawer, setToggleTabsDrawer } = props;
  const handleMobileTabs = e => {
    e.preventDefault();
    setToggleTabsDrawer(!toggleTabsDrawer);
  };
    return (
        <StyledTabView>
          <div className="menu-icon">
            <button onClick={handleMobileTabs}>
              <img src={menu_icon} alt="menu icon" />
            </button>
            <div className="menu-header">
              <h4>Mildred Awuor</h4>
              <p>Fullstack Web Developer</p>
            </div>
          </div>
        </StyledTabView>
      );
}

export default TabView;