import rest_passport from "../imgs/rest_passport.png";
import friend_finder from "../imgs/friend_finder.png";
import dark_mode from "../imgs/dark_mode.png";
import better_prof from "../imgs/better_prof.png";
import hackton_co from "../imgs/hackton_co.png";

export const projectsData = [
  {
    img: hackton_co,
    title: "Application for managing and participating in hackatons",
    github: "https://github.com/LABS-EU3/hackton-backend",
    tech_stack: "React,Redux saga,Nodejs,Postgresql",
    website: "https://app.hackton.co"
  },
  {
    img: rest_passport,
    title: "Marketing page for restaurant passport app",
    github: "https://github.com/awuorm/Build-1",
    tech_stack: "HTML, CSS",
    website: "https://restaurant-passport-marketing.netlify.com"
  },
  {
    img: friend_finder,
    title: "Friendfinder app",
    github: "https://github.com/awuorm/friend-finder-frontend",
    tech_stack: "HTML, CSS, Javascript, React.js,Redux, Node.js, express",
    website: "https://friend-finder-frontend-app.netlify.com"
  },
  {
    img: dark_mode,
    title: "Crypto tracker web app",
    github: "https://github.com/awuorm/dark-mode",
    tech_stack: "HTML, CSS, Javascript, React.js",
    website: "https://coins-dark-mode.netlify.com"
  },
  {
    img: better_prof,
    title: "Better professor web app",
    github: "https://github.com/better-professor/frontend",
    tech_stack: "HTML, CSS, Javascript, React.js",
    website: "https://better-professor-bw.netlify.com"
  }
];
