import adrian_logo from "../imgs/adrian_logo.png";
import kcb_logo from "../imgs/kcb_logo.png";
import kcaa_logo from "../imgs/kcaa_logo.png";

export const experienceData = [
    {
        companyLogo: `${adrian_logo}`,
        companyName: "Adrian Kenya",
        position:  "Enterprise Solutions Architect",
        activities: { one:"Research and design enterprise network solutions.", two: "Prepare bill of quanties (BOQs) and request for prices(RFQs).",three: "Maintain vendor and client relationships.",four:"Propose new business cases for Adrian's internal core businesses." },
        industry: "Telecommunications, ICT, Power utilities",
        location:"Nairobi, Kenya / June 2019 to August 2019"


    }, 
    {
        companyLogo: `${adrian_logo}`,
        companyName: "Adrian Kenya",
        position: "Fixed Data Engineer",
        activities: { one:"Implement and support enterprise networks(microwave,WIMAX,fibre, datacenter collocation) for Safaricom business clients in Kiambu county.", two: "Perform link audits and maintanance in case of link degradation.",three: "Perform site surveys for new connection requests.",four:"Ensure customer experience and service level agreements are met." },
        industry: "Telecommunications, ICT, Power utilities",
        location: "Kiambu County, Kenya / November 2017 to May 2019"
    },
    {
        companyLogo: `${adrian_logo}`,
        companyName: "Adrian Kenya",
        position: "Telecommunication Transmission and Fixed Data Intern",
        activities: { one:"Trained on network optimization for Safaricom Base Stations", two: "Trained on how to perform link audits and maintanance in case of link degradation for Safaricom enterprise clients.",three: "Trained on how to perform site surveys for new connection requests.",four:"Ensure customer experience and service level agreements are met." },
        industry: "Telecommunications, ICT, Power utilities",
        location: "Nairobi Kenya / September 2017 to November 2017"
    },
    {
        companyLogo: `${kcb_logo}`,
        companyName: "Kenya Commercial Bank",
        position: "IT Intern",
        activities: { one:"Trained on how to perform network installation and support (Cisco).", two: "Trained on Cisco IP telephony configuration and troubleshooting.",three: "Trained on how to perform email support, computer hardware and software troubleshooting.",four:"Trained on vendor and partner relationship management." },
        industry: "Financial Services",
        location: "Nairobi Kenya / May 2016 to September 2016"
    },
    {
        companyLogo: `${kcaa_logo}`,
        companyName: "Kenya Civil Aviation Authority",
        position: "Engineering Intern",
        activities: { one:"Trained on maintenance of the airport's radio communications equipments and air navigation equipments.", two: "Trained on equipment monitoring and device callibration.",three: "Trained on aviation engineering best practices.",four:"Responsibe for daily equipment maintenance and device monitoring."},
        industry: "Aviation",
        location: "Mombasa Kenya/ June 2015 – August 2015"
    }

]