import React from "react";
import { StyledExperience, StyledExpeCard } from "../styles";
import { experienceData } from "./exprerienceData";

const Experience = () => {
  console.log(experienceData);
  return (
    <StyledExperience>
      <h1>Experience</h1>
      <div>
        {experienceData.map((data, index) => {
          return (
            <StyledExpeCard key={index}>
              <div className="company-logo">
                <img src={data.companyLogo} alt="company logo" />
                <span>{data.companyName}</span>
                <h4>{data.position}</h4>
              </div>
              <p>
                Responsibilities:
                <ul>
                  <li>
                    {data.activities.one}
                    <br />
                  </li>
                  <li>
                    {data.activities.two}
                    <br />
                  </li>
                  <li>
                    {data.activities.three}
                    <br />
                  </li>
                  <li>
                    {data.activities.four}
                    <br />
                  </li>
                </ul>
              </p>
              <div className="industry">
                <h2>Industry: {data.industry}</h2>
                <h3>Location: {data.location}</h3>
              </div>
            </StyledExpeCard>
          );
        })}
      </div>
    </StyledExperience>
  );
};

export default Experience;
