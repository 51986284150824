import styled from "styled-components";
import tabs_back from "../src/imgs/tabs_back.svg";
import mildred_img_2 from "./imgs/mildred_img_2.jpg";
import pink_sky from "./imgs/pink_sky.jpg";



export const StyledTab = styled.div`
  width: 15vw;
  height: 100vh;
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inria Serif", serif;
  -webkit-box-shadow: 0 0 20px lightgray;
  -moz-box-shadow: 0 0 20px lightgray;
  box-shadow: 0 0 20px lightgray;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
  div {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1em;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
    width: 100%;
    background-color: antiquewhite;
    h4 {
      margin: 0;
      padding: 0;
      color: #f48668;
      font-size: 1.5em;
      font-weight: 0;
    }
    p {
      color: darkgrey;
    }
  }
  a {
    text-decoration: none;
    padding: 1em;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    :hover {
      background-color: antiquewhite;
    }
    :active {
      background-color: antiquewhite;
    }
    img {
      width: 20%;
      padding-right: 0.3em;
    }
  }
`;

export const StyledHome = styled.div`
  width: 85vw;
  height: 100vh;
  background-image: url(${pink_sky});
  background-size: cover;
  image-rendering: smooth;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100vw;
    background-image: url(${pink_sky});
    background-size: cover;
    image-rendering: smooth;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
  }
  @media (max-width: 500px) {
    width: 100vw;
    background-image: none;
    height: 100%;
  }
  figure {
    width: 50%;
    height: 60%;
    @media (max-width: 1024px) {
      width: 80%;
      height: 40%;
    }
    @media (max-width: 500px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5em;
      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5em;
      }
      @media (max-width: 500px) {
        display: none;
      }
    }
    
  }

  .h2-cont {
    width: 50%;
    margin-right: 2%;
    margin-top: 2%;
    height: 30%;
    @media (max-width: 1024px) {
      width: 80%;
      height: 20%;
      margin: 0;
      margin-bottom:50%;
    } 

    @media (max-width: 500px) {
      width: 100vw;
      height: 100vh;
      background-image: url(${mildred_img_2});
      background-size: cover;
      image-rendering: smooth;
      margin: 0;
    }

    h2 {
      color: lightcoral;
      font-weight: normal;
      font-size: 1.7em;
      line-height: 1.5em;
      padding: 0.5em;

      @media (max-width: 1024px) {
        font-size: 1.5em;
        color: #9e6c48;
      }

      @media (max-width: 500px) {
        font-size: 1em;
        color: lightcoral;
        font-weight: normal;
        font-size: 1.2em;
        line-height: 1.5em;
        padding: 0.5em;
        margin-top: 80%;
        margin-left: 20%;
        background-color: antiquewhite;
        width:60%;

      }
    }
  }
`;

export const StyledAbout = styled.div`
  width: 85vw;
  height: 100vh;
  background-image: url(${tabs_back});
  background-size: cover;
  color: black;

  @media (max-width: 1024px) {
    width: 100vw;
    height: 90vh;
  }

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }

  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
  h5 {
    font-size: 1.5em;
    color: #9e6c48;
    margin-left: 5vw;
    padding: 1em;
    border-left: 0.3em solid #9e6c48;
    font-weight: normal;
    @media (max-width: 1024px) {
      font-size: 2em;
    }

    @media (max-width: 500px) {
      font-size: 1em;
    }
  }

  p {
    color: black;
    font-size: 1.5em;
    margin-left: 5vw;
    line-height: 1.5em;
    @media (max-width: 1024px) {
      font-size: 2em;
      padding: 1em;
    }

    @media (max-width: 500px) {
      font-size: 1em;
    }
  }
  h3 {
    color: #9e6c48;
    font-size: 1.5em;
    margin-left: 5vw;
    @media (max-width: 500px) {
      font-size: 1em;
    }

    span {
      color: lightcoral;
      font-size: 1em;
      font-weight: normal;
    }
  }
`;

export const StyledExperience = styled.div`
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  color: black;
  background-image: url(${tabs_back});
  background-size: cover;

  @media (max-width: 1024px) {
    width: 100vw;
    height: 90vh;
  }

  @media (max-width: 500px) {
    width: 100vw;
    height: 100vh;
  }
  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
`;

export const StyledExpeCard = styled.div`
  background-color: white;
  margin: 1em;
  width: 70%;
  border-top: 1em solid lightcoral;
  border-radius: 1em;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  padding: 2em;

  .company-logo {
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 500px) {
      flex-direction: column;
    }
    img {
      width: 10vw;
      height: 12vh;

      @media (max-width: 1024px) {
        display: none;
      }

      @media (max-width: 500px) {
        display: none;
      }
    }

    span {
      font-size: 1.5em;
      color: #9e6c48;
      font-weight: normal;

      @media (max-width: 1024px) {
        font-size: 2em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }

    h4 {
      font-size: 1.2em;
      color: #9e6c48;
      font-weight: normal;
      @media (max-width: 1024px) {
        font-size: 2em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
  }

  p {
    font-size: 1.2em;
    line-height: 1.5em;

    @media (max-width: 1024px) {
      font-size: 1em;
    }

    @media (max-width: 500px) {
      font-size: 1em;
    }
  }

  ul {
    li {
      width: 80%;
      font-size: 1.2em;
      line-height: 1.5em;
      padding: 0.5em;

      @media (max-width: 1024px) {
        font-size: 1.2em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
  }

  .industry {
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
      flex-direction: column;
    }

    @media (max-width: 500px) {
      flex-direction: column;
    }

    h2 {
      font-size: 1em;
      /* font-weight: normal; */
      @media (max-width: 1024px) {
        font-size: 1.4em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }

    h3 {
      font-size: 1em;
      /* font-weight: normal; */
      @media (max-width: 1024px) {
        font-size: 1.4em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
  }
`;

export const StyledProjects = styled.div`
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  color: black;
  background-image: url(${tabs_back});
  background-size: cover;
  @media (max-width: 1024px) {
    width: 100vw;
  }

  @media (max-width: 500px) {
    width: 100vw;
  }
  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const StyledProCard = styled.div`
  background-color: white;
  width: 50%;
  height: 70vh;
  margin: 1em;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  @media (max-width: 1024px) {
    width: 80%;
    height: 50vh;
  }

  @media (max-width: 500px) {
    width: 70%;
    height: 70vh;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 50%;
    margin: 0;
  }
  div {
    margin-top: 1em;
    height: 50%;
    h5 {
      font-size: 1em;
      margin: 0.5em;
      color: #9e6c48;
      font-weight: bold;
      @media (max-width: 1024px) {
        font-size: 1.5em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
    p {
      font-size: 1em;
      margin: 0.5em;
      margin-bottom: 0;
      color: #9e6c48;
      font-weight: bold;
      @media (max-width: 1024px) {
        font-size: 1.5em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      a {
        font-size: 1em;
        margin: 0;
        border: 1px solid lightgray;
        margin: 1em;
        img {
          object-fit: contain;
          width: 8vw;
          height: 10vh;
          @media (max-width: 1024px) {
            width: 10vw;
            height: 7vh;
          }

          @media (max-width: 500px) {
            width: 10vw;
            height: 5vh;
          }
        }
      }
    }
  }
`;

export const StyledSkills = styled.div`
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  color: black;
  background-image: url(${tabs_back});
  background-size: cover;
  @media (max-width: 1024px) {
    width: 100vw;
  }

  @media (max-width: 500px) {
    width: 100vw;
  }
  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
`;

export const StyledSkillsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 1.5em;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    margin: 1em;
    h2 {
      font-size: 1.5em;
      font-weight: normal;
      width: 100%;
      padding: 0.5em;
      border-bottom: 1px solid lightgray;
    }
    div {
      box-shadow: none;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
      }
      img {
        width: 10vw;
        height: 13vh;
        padding: 0.5em;
        @media (max-width: 1024px) {
          width: 8vw;
          height: 6vh;
        }

        @media (max-width: 500px) {
          width: 20vw;
          height: 10vh;
        }
      }
    }
  }
`;

export const StyledEducation = styled.div`
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  color: black;
  background-image: url(${tabs_back});
  background-size: cover;

  @media (max-width: 1024px) {
    width: 100vw;
  }

  @media (max-width: 500px) {
    width: 100vw;
  }

  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
  div {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    div {
      background-color: white;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
      margin: 1em;
      div {
        box-shadow: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid lightgray;
        img {
          padding: 1em;
          width: 5vw;
          height: 10vh;
          @media (max-width: 1024px) {
            margin-left: 1em;
            width: 10vw;
            height: 10vh;
          }

          @media (max-width: 500px) {
            width: 10vw;
            height: 5vh;
          }
        }
        h2 {
          font-size: 1.3em;
          font-weight: normal;
          color: #9e6c48;
          @media (max-width: 1024px) {
            font-size: 2em;
          }

          @media (max-width: 500px) {
            font-size: 1.3em;
          }
        }
      }
      h2 {
        font-size: 1.1em;
        font-weight: normal;
        padding: 0.5em;
        @media (max-width: 1024px) {
          font-size: 1.5em;
        }

        @media (max-width: 500px) {
          font-size: 1.3em;
        }
      }
    }
  }
`;

export const StyledContactUs = styled.div`
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  color: black;
  background-image: url(${tabs_back});
  background-size: cover;
  @media (max-width: 1024px) {
    width: 100vw;
  }

  @media (max-width: 500px) {
    width: 100vw;
  }
  h1 {
    background-color: lightcoral;
    margin-top: 0;
    color: white;
    padding: 1em;
    font-size: 1.7em;
    font-weight: normal;
  }
  div {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    h2 {
      font-size: 1.4em;
      font-weight: normal;
      color: #9e6c48;
      @media (max-width: 1024px) {
        font-size: 1.7em;
      }

      @media (max-width: 500px) {
        font-size: 1em;
      }
    }
    background-color: white;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      box-shadow: none;
      padding: 1em;

      @media (max-width: 500px) {
        flex-direction: column;
      }
      a {
        img {
          width: 5vw;
          height: 10vh;
          @media (max-width: 1024px) {
            width: 10vw;
            height: 10vh;
          }

          @media (max-width: 500px) {
            width: 10vw;
            height: 10vh;
          }
        }
      }
    }
  }
`;

export const StyledMobileTabs = styled.div`
  height: 100vh;
  width: 30vw;
  position: fixed;
  padding: 0.5em;
  left: 0;
  top: 8vh;
  z-index: 200;
  background: antiquewhite;
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inria Serif", serif;
  .Resume {
    text-decoration: none;
    padding: 1em;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5em;
    @media (max-width: 500px) {
      font-size: 0.8em;
    }
    :hover {
      background-color: antiquewhite;
    }
    :active {
      background-color: antiquewhite;
    }
    img {
      width: 20%;
      padding-right: 0.3em;
    }
  }
  button {
    background-color: antiquewhite;
    border: none;
    margin: 0;
    a {
      text-decoration: none;
      padding: 1em;
      color: black;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5em;
      @media (max-width: 500px) {
        font-size: 0.8em;
      }
      :hover {
        background-color: antiquewhite;
      }
      :active {
        background-color: antiquewhite;
      }
      img {
        width: 20%;
        padding-right: 0.3em;
      }
    }
  }
`;

export const StyledBackDrop = styled.div`
  display: none;
`;

export const StyledApp = styled.div`
  box-sizing: border-box;
  font-family: "Inria Serif", serif;
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
`;

export const StyledMobileApp = styled.div`
  box-sizing: border-box;
  font-family: "Inria Serif", serif;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const StyledTabView = styled.div`
  display: flex;
  flex-direction: row;
  background-color: antiquewhite;
  width: 100vw;
  height: 8vh;
  top: 0;
  .menu-icon {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background-color: antiquewhite;
    width: 100%;
    height: 100%;
    button {
      border: none;
      background-color: antiquewhite;
      width: 20%;
      height: 100%;
      img {
        width: 11vw;
        height: 100%;
        margin-right: 30%;
        @media (max-width: 500px) {
          margin-right: 10%;
        }
      }
    }
    .menu-header {
      display: flex;
      flex-direction: column;
      background-color: antiquewhite;
      justify-content: center;
      align-items: center;
      margin-left: 10%;
      @media (max-width: 500px) {
        margin-left: 10%;
      }
      h4 {
        color: lightcoral;
        font-size: 2em;
        margin-left: 5%;
        margin: 0;
        margin-bottom: 2%;
        @media (max-width: 500px) {
          font-size: 1.2em;
        }
      }
      p {
        color: #9e6c48;
        font-size: 1.3em;
        margin: 0;
        text-align: center;
        @media (max-width: 500px) {
          font-size: 1em;
        }
      }
    }
  }
`;
